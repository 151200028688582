import React, { FC, useRef, useState } from 'react';

import {
  Button,
  Center,
  Flex,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { Form, Formik, FormikProps } from 'formik';

import SideBar from './SideBar';
import OrderForm from './OrderForm';
import NavBar from 'components/core/NavBar';

import { Banner, Container } from './CreateOrder.style';

import { getInitialValues } from './CreateOrder.utils';

import { Site } from 'types/site';
import { CreateOrderViewProps } from './CreateOrder.props';

const CreateOrderView: FC<CreateOrderViewProps> = ({
  portal,
  allSkus,
  isLoading,
  isCreating,
  _selected,
  onCreateOrder,
}) => {
  const formikRef = useRef<FormikProps<any>>(null);

  const [selected, setSelected] = useState<Site[]>(_selected);

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: selected?.length > 1,
  });

  return (
    <Flex flex={1}>
      {isLoading ? (
        <Center flex={1}>
          <Spinner size="xl" />
        </Center>
      ) : (
        <>
          <SideBar
            sites={portal?.sites || []}
            groups={portal?.groups || []}
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={() => {
              onClose();

              formikRef?.current?.handleReset();
            }}
            selected={selected}
            setSelected={setSelected}
          />
          <Container>
            <NavBar groupName={portal?.groups?.[0]?.companyName || ''} />
            <Container>
              <Banner />
              <Container align="center" mt="20px">
                <Formik
                  innerRef={formikRef}
                  enableReinitialize
                  initialValues={getInitialValues(selected, allSkus)}
                  onSubmit={(values) => {
                    let payload = values;

                    const items = values?.items as any[];

                    items?.forEach((i) => {
                      i.details?.forEach((d: any) => {
                        d.casesCount = Number(d.casesCount || '0');
                      });
                      i.details = i.details?.filter(
                        (d: any) => d.casesCount > 0,
                      );
                    });
                    if (isOpen) {
                      payload = {
                        ...values,
                        items: items,
                        group: portal?.groups?.[0]?._id,
                      };
                    }
                    onCreateOrder(payload);
                  }}>
                  {() => (
                    <Form>
                      <Container maxH="58vh" overflowY="auto">
                        {selected.length > 0 ? (
                          selected.map((site, index) => (
                            <OrderForm
                              site={site}
                              index={index}
                              key={site._id + site.createdAt}
                            />
                          ))
                        ) : (
                          <Center p="50px">
                            <Text>No sites present</Text>
                          </Center>
                        )}
                      </Container>
                      {selected.length > 0 && Boolean(selected[0]?.packages) && (
                        <Center mt="30px">
                          <Button type="submit" isLoading={isCreating}>
                            Submit Form
                          </Button>
                        </Center>
                      )}
                    </Form>
                  )}
                </Formik>
              </Container>
            </Container>
          </Container>
        </>
      )}
    </Flex>
  );
};

export default CreateOrderView;
